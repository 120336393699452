import './global.css'

import Helmet from 'react-helmet'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from 'react'
import styled from 'styled-components'
import BackgroundVideo from './background.mp4'
import Poster from './poster.png'
import BorderImg from './border.png'


const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: no-wrap;
    min-height: 100vh;
    z-index: 1;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`

const NavItem = styled(Link)`
    margin: 32px;
    margin-bottom: 0px;
    color: rgba(255,255,255,0.65);
    text-decoration: none;
    
    &:hover{
        opacity: 0.7;
    }
`

const Links = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: ${props => props.flipped ? "0px" : "64px"};
    margin-top: ${props => props.flipped ? "64px" : "0px"};


`


const Yield = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
`

const Video = styled.video`
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`

const Desktop = styled.div`
    @media (max-width: 1000px){
        display: none;
    }
`

const Mobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1000px) {
        display: none;
    }
`

const Border = styled.div`
    position: relative;
    border: 14px solid;
    border-image: url("${BorderImg}") 28;
    width: calc(100vw - 128px);
    min-height: calc(100% - 128px);
    margin: 64px;
    display: flex;
`

const LilBorder = styled.div`
    border: 9px solid;
    border-image: url("${BorderImg}") 28;
    width: 244px;
    height: 344px;
    position: relative;
    margin-top: 64px;
`

const LilLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
`

const LilNavItem = styled(Link)`
    margin-bottom: 0px;
    color: rgba(255,255,255,0.65);
    text-decoration: none;
    
    &:hover{
        opacity: 0.7;
    }
`

const ChildWrap = styled.div`
    margin-top: 32px;
    margin-bottom: 32px
`

const Layout = ({ children, flipped }) => {

  return (
  <>
    <Helmet>
        <title>Heath Cards</title>
        <meta
            name="description"
            content="Really good playing cards"
        />
        <html lang="en" />
    </Helmet>
    
    <Wrapper>
            <Desktop>
                <Border>
                    <Link to="/">
                        <StaticImage src="../images/logo-borders-new.png" style={{
                            position: 'absolute',
                            top: '-12px',
                            left: '-32px'
                        }} width={50}/>
                    </Link>

                    <Yield>
                        {!flipped &&
                           children
                        }
                        <Links flipped={flipped}>
                            <NavItem as="a" href="https://heathcards.shop">
                            <StaticImage src="../images/shop.png" height={24}/> 
                            </NavItem>
                            <NavItem to="/contact">
                                <StaticImage src="../images/contact-new.png" height={24}/>
                            </NavItem>
                            <NavItem to="/media">
                                <StaticImage src="../images/media.png" height={24}/>
                            </NavItem>
                        </Links>

                        {flipped &&
                           children
                        }
                    </Yield>
                    
                    <Link to="/">
                        <StaticImage src="../images/logo-borders-new.png" style={{
                            position: 'absolute',
                            bottom: '-12px',
                            right: '-32px',
                            transform: 'rotate(180deg)'
                        }} width={50}/>
                    </Link>
                </Border>
            </Desktop>

            <Mobile>

                <LilBorder>
                    <Link to="/">
                        <StaticImage src="../images/logo-borders-new.png" style={{
                            position: 'absolute',
                            top: '-68px',
                            left: 'calc(50% - 17px)',
                            transform: 'rotate(-90deg)'
                        }} width={34}/>
                    </Link>

                    <LilLinks>
                        <LilNavItem as="a" href="https://heathcards.shop">
                            <StaticImage src="../images/shop.png" height={22}/> 
                        </LilNavItem>
                        <LilNavItem to="/contact">
                            <StaticImage src="../images/contact-new.png" height={22}/>
                        </LilNavItem>
                        <LilNavItem to="/media">
                            <StaticImage src="../images/media.png" height={22}/>
                        </LilNavItem>
                    </LilLinks>
                </LilBorder>
                
                <ChildWrap>
                    {children}
                </ChildWrap>

            </Mobile>
    </Wrapper>

    <Video playsInline autoPlay muted loop poster={Poster}>
        <source src={BackgroundVideo} type="video/mp4" />
    </Video>
  </>
  )
}

export default Layout